<template>
  <div>
    <v-btn :color="(!isListening) ? 'primary' : 'error'" class="btn-float" dark normal fab @click="onToggleDictado">
      <v-icon dense size="25px" v-if="isListening">mdi-stop</v-icon>
      <v-icon dense size="25px" v-else>mdi-microphone</v-icon>
    </v-btn> 
  </div>
</template>

<script>

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
import APPutils from '@/utils/APPutils.js'

export default {
  name: 'DictadoPorVoz',
  components: {
  },
	props: {
      activo: {
        type: Boolean,
        default: false
      },
			onLecturaCompletada: Function
    },
    data() {
      return {
      recognition: null,
        isListening: false,
        sTextoTranscrito: '', // Aquí almacenaremos el texto transcrito
      };
    },
    created(){
      vm=this;
    },
    methods: {
      onToggleDictado(){
        if(typeof IApp !== 'undefined'){
          this.valor = false
          APPutils.onIniciarDictadoVozApp();
        }else{ 
          if(this.isListening){
            this.detenerDictado()
          }else{
            this.iniciarDictado()
          }
        }

      },
      iniciarDictado() {
        if (!SpeechRecognition) {
          alert("API de reconocimiento de voz no disponible.");
          return;
        }

        this.recognition = new SpeechRecognition();
        this.recognition.lang = 'es-ES'; // Configurar el idioma (puedes cambiarlo según el idioma necesario)
        this.recognition.continuous = false; // Define si debe continuar escuchando después de reconocer una frase
        this.recognition.interimResults = false; // Define si se deben mostrar resultados provisionales
        this.sTextoTranscrito = ''
        // Evento cuando se detecta voz
        this.recognition.onresult = (event) => {
          const transcript = event.results[0][0].transcript;
          this.onAgregarTexto(transcript);
        };

        // Evento para manejar errores
        this.recognition.onerror = (event) => {
          console.error("Error en el reconocimiento de voz: ", event.error);
        };

        // Evento cuando termina el reconocimiento
        this.recognition.onend = () => {
          this.isListening = false;
        };

        // Iniciar reconocimiento
        this.recognition.start();
        this.isListening = true;
      },
      detenerDictado() {
        this.isListening = false;
        if (this.recognition) {
          this.recognition.stop();
        }
      },
      onAgregarTexto(sTranscripcion){
          this.sTextoTranscrito += sTranscripcion + ' '; // Agregar al texto transcrito
          this.$emit("input", this.sTextoTranscrito);
          this.$emit("onDictado", this.sTextoTranscrito);
      }
  },
  computed: {
  },
}

var vm = null;
window.onDictadoVozCompletado = function(sTexto) {
  vm.onAgregarTexto(sTexto);
}


</script>
<style lang="scss" scoped>
.algo{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: .5);
}
.btn-cerrar{
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
}
.btn-linterna{
  font-size: 70px !important;
}
.linterna{
  height: 30vh !important;
  position: absolute;
  z-index: 1;
}
</style>