<template>
  <div class="margen-form">
    <v-container>

      <!--Seccion titulo-->
      <div v-if="!bIsReporte">
        <v-col class="pa-0 mb-2" cols="12">    
          <HeaderVista :sTitulo="sTitulo" :sSubTitulo="sSubTitulo" sColor="black--text">
            <template v-slot:icono>
              <v-icon large color="red"> mdi-map-marker </v-icon>
            </template>
          </HeaderVista>
        </v-col>
      </div>
      <!--v-card
        width="100%"
        class="pa-5 mt-5 card-rounded over-hidden"
      >
        <v-col class="pa-0 mb-2 justify-center" cols="12">
          <v-row justify="start">
            <span class="text-size-2 ml-2 mb-5">{{ $STR.lblQueDeseaReportar }}</span>
          </v-row>
          <v-row justify="center">
            <ToggleButton
              v-model="tipoReporte"
              :aData="aValores"
              >
            </ToggleButton>
          </v-row>
        </v-col>
      </v-card-->

      <v-card
        width="100%"
        class="pa-5 mt-5 card-rounded over-hidden"
      >
        <span class="text-size-2 ml-2 mb-5">Detalles</span>
        <ContenedorCampos class="mt-5" :titulo="$STR.cmpObs"  />
        <v-textarea
          v-model="sObservacion"
          :placeholder="$STR.cmpAgregarObservaciones"
          rows="3"
          row-height="10"
          outlined
          required
        />

        <v-col no-gutters class="pa-0 mt-3"> 
          <Fotos
            :key="oKeyFoto"
            v-model="aFotos"
            :sTitulo='$STR.lblAnexos'
          />
        </v-col>
      </v-card>

      <v-sheet
        class="text-center contenedor-bottom mt-5"
        >
          <v-col class="pa-0">
            <v-row no-gutters justify="center" align="center">
              <v-btn block @click="onEnviar" large dark color="primary" class="btn-primary btn-rounded">
               {{ $STR.btnEnviar }}
              </v-btn>
            </v-row> 
            <v-row no-gutters  justify="center" align="center" class="mt-2">
              <v-btn block @click="onCancelar" large dark color="error" outlined class="btn-primary btn-rounded">
                {{ $STR.btnCancelar }}
              </v-btn>
            </v-row>  
          </v-col>
      </v-sheet>

    </v-container>
    <DialogMensaje
      v-model="bDialogCamposObligatorios"
      :titulo="sTituloMensaje"
      :texto="sMensajeError"
      :bOcultarCancelar="true"
      @onAceptar="fAceptar"
    >
      <template v-slot:icono>
        <v-icon large :color="sIconoColor"> {{ sIcono }}</v-icon>
      </template>
    </DialogMensaje>
  </div>
  
</template>
<script>
import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
import HeaderVista from '@/components/HeaderVista.vue';

import { mapGetters } from 'vuex'
import EditTextAlfanumerico from '@/components/dinamicos/campos/EditTextAlfanumerico.vue';
import ToggleButton from '@/components/ToggleButton.vue'
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue';
import Fotos from '@/components/Fotos.vue'
import DialogMensaje from '@/components/DialogMensaje.vue'
import APPutils from '@/utils/APPutils.js'
import HoraFecha from "@/utils/Hora_Fecha.js"
import GPSLocation from '@/utils/GPSlocation.js'

export default {
  name: 'ReporteMarcacion',
  components: {
    HeaderVista,
    EditTextAlfanumerico,
    ContenedorCampos,
    Fotos,
    DialogMensaje,
    ToggleButton
  },
  data() {
    return {
      sIdRondaCalendario : '0',
      sCodigo: '',
      nIdPunto : 0,
      idSedeBase : 0,
      aPuntos:[],
      oRonda:null,
      sTitulo: '',
      sSubTitulo: '',
      tipoReporte: "2",
      aValores: [
        {ID:"1", DatoMostrar:"Novedad"}, 
        {ID:"2", DatoMostrar:"Marcacion"}
      ],
      sObservacion: '',
      oKeyFoto: 1,
      aFotos:[],
      oLocation : {},
      disabledGuardar : false,
      bIsReporte : false,
      bErrorPermisos : false ,
      bDialogCamposObligatorios:false,
      sTituloMensaje:"",
      sMensajeError:"",
      sFormulario: "Ronda Marcacion",
      fAceptar:()=>{},
      sIcono: "",
      sIconoColor: "",
      
    };
  },
  async mounted() {
    if(this.$route.params.idRonda !== undefined)this.sIdRondaCalendario = this.$route.params.idRonda;
    if(this.$route.params.sCodigo !== undefined)this.sCodigo = this.$route.params.sCodigo;
    
    this.oRonda = RondasRepository.onGetRondaById(this.sIdRondaCalendario)
    
    if(this.oRonda != undefined){
      this.sTitulo = this.oRonda.nombre
    }

    this.bIsReporte = this.sCodigo == ''
    
    // Se llama despues de obtener los parametros de la url
    this.onCargarInformacion()

    try{
      this.oLocation = await GPSLocation.getCurrentPosition()
      this.bErrorPermisos = false
    }catch(e){
      if(e.message=='User denied Geolocation'){
        this.bErrorPermisos = true
      }
      // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
    } 
  },
  methods: {
    onCancelar(){
      this.$router.back()
    },
    async onEnviar(){
      if(!this.isFormularioValido()){
        const sParams = `app=PWA`
        const aNombreFotos = [];
        this.aFotos.forEach( async foto => {
          aNombreFotos.push({ fotoNombre : foto.NombreFoto})
        })

        const sReporte = this.aValores.find(reporte => reporte.ID == this.tipoReporte)

        const oBody = {
          imei : this.getimei,
          app : "ReporteMarcacion",
          sIdActividadCalendario: this.sIdRondaCalendario,
          sIdActividad: this.oRonda.sIdActividad,
          idPunto: this.nIdPunto,
          idSede: this.idSedeBase,
          sObservacion : this.sObservacion,
          galeria : aNombreFotos,
          tipoRespuesta: "marcacion",
          Latitud : this.oLocation.coords.latitude,
          Longitud : this.oLocation.coords.longitude,
          fecha : HoraFecha.getNow(),
        }
        const oResponse = await RondasRepository.onReporteMarcacion(sParams, oBody, this.aFotos, this.getisAPP)
        
        if(!Object.prototype.hasOwnProperty.call(oResponse, 'nStatusCode')) {
          if(this.getisAPP) {
            APPutils.enviarDataOffline(oBody, this.sFormulario)
            APPutils.onSubirFotosApp(this.sFormulario)
          }
        }else {
          if ((oResponse.nStatusCode == 200) || (oResponse.nStatusCode == 220)  ){
            if(this.getisAPP) {
              APPutils.onSubirFotosApp(this.sFormulario)
            }
            switch (oResponse.nStatusCode) {
              case 200: {
                this.onActualizarEstadoRonda(oBody)
                this.$swal({
                  timer: 1500,
                  icon: `${oResponse.sStatus}`,
                  title: `${oResponse.sMessage}`,
                  showConfirmButton: false
                })
                break
              }
              case 220: {
                this.onNotificarRondaEnProgreso(this.sIdRondaCalendario, this.getisAPP)
                break
              }
            }
            //APPutils.registro_OK()
          } else {
            if(this.getisAPP) {
              APPutils.enviarDataOffline(oBody, this.sFormulario)
              APPutils.onSubirFotosApp(this.sFormulario)
            }
          }
        }
      }else{
        this.sTituloMensaje="Campos requeridos"
        this.bDialogCamposObligatorios = true
        this.sIcono = 'mdi-alert'
        this.sIconoColor = 'red'
      }
    },
    onCargarInformacion(){
      //Valido si es Seif o SM para cargar la info de la sede y saber el nombre la tabla puntos
      if(this.getsTipoApp == "1"){
        if(this.getnIDSedeBase > 0){
          this.idSedeBase = this.getnIDSedeBase
        }
      }
      let oPunto = RondasRepository.onGetPuntoByCodigo(this.sIdRondaCalendario, this.sCodigo)
      if(oPunto !=undefined){
        this.preguntas = oPunto.aPreguntas
        this.sTitulo = oPunto.sNombrePunto
        this.nIdPunto = oPunto.sIdPunto
        this.sSubTitulo = `Asociado a la Ronda: ${oPunto.sNombreRonda}`
      }
      
    },
    isFormularioValido(){
      this.sMensaje = ''
      let error = false

      if(this.tipoReporte=="0"){
        this.sMensajeError += '- Tipo de Reporte no diligenciado.'
        error = true
      }

      if(this.nIdPunto==0){
        this.sMensajeError += '- Punto no se encontro.'
        error = true
      }

      return error
    },
    onActualizarEstadoRonda(oBody){
      if(RondasRepository.onActualizarProgresoRonda(this.sIdRondaCalendario, this.nIdPunto, oBody, this.getisAPP)){
        this.volverAlInicio()
      }else{
        this.salir()
      }
    },
    salir () {
      this.$router.replace(`/PuntosPorRonda/${this.sIdRondaCalendario}`);
    },
    onNotificarRondaEnProgreso (sIdActividadCalendario, bIsApp=false) {
      console.log("AQUI", sIdActividadCalendario);
      this.sTituloMensaje="Ronda en progreso"
      this.sMensajeError = this.$STR.msgRondaBloqueadaIniciadaDesdeOtroUsuario
      this.sIcono = 'mdi mdi-check-circle-outline'
      this.sIconoColor = 'green'
      this.bDialogCamposObligatorios = true
      RondasRepository.onActualizarEstadoRonda(sIdActividadCalendario, "Bloqueado", bIsApp)
      this.fAceptar = () => {
        this.volverAlInicio()
      }
    },
    volverAlInicio() {
      window.history.replaceState(null, '', '/DashboardRondas');
      window.history.pushState(null, '', '/DashboardRondas');
      this.$router.replace('/DashboardRondas')
    },
  },
  computed: {
    ...mapGetters(['getbMenuBlock','getimei', 'getisAPP', 'getaSedes', 'getnIDSedeBase', 'getsTipoApp']),
    ...mapGetters('dataBase',['getdataBase']),
  }
}
</script>
<style lang="scss" scoped>
.contenedor-bottom {
  background-color: unset;
  bottom: 0;
  width: 100%;
  margin-bottom: 50px;
}

</style>  