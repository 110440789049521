export default {
    ModulosApp:[
        {Nombre_app:'ActivityReporteNovedadIncidente', Icon:'mdi-file-document-outline',Titulo:'Registrar novedad o incidente',Descripcion:'Registrar una novedad o incidente',Link:'/ReporteNovedadIncidente'},
        {Nombre_app:'ActivityMinuta', Icon:'mdi-clipboard-text-outline',Titulo:'Registro de Elementos',Descripcion:'Registra un elemento',Link:'/elementos-qr'},
        {Nombre_app:'ActivityDinamicos', Icon:'mdi-layers-triple-outline',Titulo:'Dinámicos',Descripcion:'Registrar un registro',Link:'/ListaDinamicos'},
    ]
  //  {Nombre_app:'', Icon:'mdi-account-supervisor-circle',Titulo:'Supervisión',Descripcion:'Realizar una Supervisión',Link:''},
  //  {Nombre_app:'', Icon:'mdi-shield-account-outline',Titulo:'Administrativo',Descripcion:'Registrar un Registro',Link:''},
  //  {Nombre_app:'', Icon:'mdi-file-search-outline',Titulo:'Acta Seguimiento',Descripcion:'Registrar un Acta Seguimiento',Link:''},
  //  {Nombre_app:'', Icon:'mdi-file-document-outline',Titulo:'Acta Reunión',Descripcion:'Registrar un Reunion',Link:''},
  //  {Nombre_app:'', Icon:'mdi-comment-question-outline',Titulo:'PQR',Descripcion:'Registrar un PQR',Link:'pqrInicioTicket'},
  //  {Nombre_app:'', Icon:'mdi-puzzle-edit-outline',Titulo:'Crear Prospecto',Descripcion:'Registrar un prospecto',Link:''},
  //  {Nombre_app:'', Icon:'mdi-home-edit-outline',Titulo:'Visita Prospecto',Descripcion:'Registrar una visita',Link:''},
  //  {Nombre_app:'', Icon:'mdi-map-marker-path',Titulo:'Rondas',Descripcion:'Realiza un reporte',Link:''},
  //  {Nombre_app:'', Icon:'mdi-human-male-male',Titulo:'Acompañamiento',Descripcion:'Inicia un acompañamiento',Link:''},
  //  {Nombre_app:'', Icon:'mdi-target-account',Titulo:'Inspección',Descripcion:'Realiza una inspección',Link:''},
  //  {Nombre_app:'', Icon:'mdi-boom-gate',Titulo:'Parqueadero',Descripcion:'Registra un vehiculo',Link:''},
  //  {Nombre_app:'', Icon:'mdi-window-shutter-alert',Titulo:'Apertura y Cierre',Descripcion:'Realizar una Apertura o Cierre',Link:''},
  //  {Nombre_app:'', Icon:'mdi-badge-account-horizontal-outline',Titulo:'Visitantes',Descripcion:'"Registra un visitante',Link:''},
  //  {Nombre_app:'', Icon:'mdi-email-multiple-outline',Titulo:'Correspondencia',Descripcion:'Registrar la correspondencia',Link:''},
  //  {Nombre_app:'', Icon:'mdi-message-alert-outline',Titulo:'Novedades Operativas',Descripcion:'Registro de novedades e incidentes',Link:''},
  //  {Nombre_app:'', Icon:'mdi-book-open',Titulo:'Minuta',Descripcion:'Ingresa y registra tus anotaciones',Link:''},
  } 
  