<template>
  <div>
    <v-dialog
      v-model="valor"
      scrollable 
      persistent 
      :overlay="false"
      max-width="500px">
      
      <v-card >
        <v-toolbar dark color="primary" dense >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCerrar" class="btn-close">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        
          <v-col justify="center" align="center">
            <v-row no-gutters justify="center" class="ma-2">
              <video 
                v-show="valor"
                ref="qrVideo" 
                :srcObject.prop="oVideoStream"
                autoplay 
                playsinline 
                class="pa-0 ma-0"
                width="300px"
                height="310px"
                >
              </video>
            </v-row>
            <v-row no-gutters justify="center" class="ma-2">
              <span class="text-semibold">Acerca la cámara trasera del celular al código QR</span>
            </v-row>
            <v-row no-gutters justify="center" class="ma-2">
              <span class="red--text" v-if="sMsgError != ''">{{ sMsgError }}</span>
            </v-row>
          </v-col>  
      </v-card>
    </v-dialog>  
  </div>
</template>

<script>
import APPutils from '@/utils/APPutils.js'
import isMovil from  '@/utils/isMovil.js'

export default {
  name: 'LecturaQR',
  components: {
  },
	props: {
      activo: {
        type: Boolean,
        default: false
      },
			onLecturaCompletada: Function
    },
  data () {
		return {
      valor: true,
      camera: 'off',
      sMsgError: '',
      oVideoStream: null,
      oBarcodeDetector: null,
      bEscaneando: false,
    }
	},
	mounted() {
		this.onLeerQR();
	},
  created(){
    vm=this;
  },
  methods: {
    onGuardarQR (sCodigoQR) {
      if(sCodigoQR!=undefined){
        this.onLecturaCompletada(sCodigoQR)
        this.onDetenerEscaneo()
        this.valor = false
      }
    },
    onLeerQR(){
      if(typeof IApp !== 'undefined'){
        this.valor = false
        APPutils.onLeerQR();
      }else{
        this.valor = true
        this.onInicializarDetector()
      }
    },
    onCerrar(){
      this.onDetenerEscaneo()
      this.valor = false
      this.$emit('cerrarQR');
    },
    async onInicializarDetector(){
      if (!("BarcodeDetector" in globalThis)) {
        this.sMsgError = "Este navegador no soporta lectura de QRs"
      } 
      else {
        // BarcodeDetector.getSupportedFormats().then((supportedFormats) => {
        //   supportedFormats.forEach((format) => console.log(format));
        // });
        try {
          // Inicializar el detector de códigos de barras y especificar formatos
          this.oBarcodeDetector = new BarcodeDetector({
            formats: ["codabar", "qr_code"],
          });

          // Solicitar acceso a la cámara
          this.oVideoStream = await navigator.mediaDevices.getUserMedia({ 
            video: { 
              width: {max: 1280} , height: {max:720},
              audio:false,
              facingMode: (isMovil.validate() ?  "environment" : "user" ) 
            } 
          });

          //Configuracion del componente de Video de Camara
          const videoElement = this.$refs.qrVideo;
          videoElement.srcObject = this.oVideoStream;
          videoElement.onloadedmetadata = () => {
            this.onIniciarEscaneo(videoElement);
          };

        } catch (error) {
          this.sMsgError = `Error de camera: ${error.message}`;
        }
      }
    },
    async onIniciarEscaneo(videoElement) {
      if (!this.oBarcodeDetector) return;
      this.bEscaneando = true; // Iniciar el escaneo

      const onDetectar = async () => {
        if (!this.bEscaneando) return; // Si no está escaneando, salir del loop
        try {
          const barcodes = await this.oBarcodeDetector.detect(videoElement);
          if (barcodes.length > 0) {
            console.log(barcodes[0].rawValue);
            this.onGuardarQR(barcodes[0].rawValue); // Mostrar el código QR detectado
          } 
        } catch (error) {
          this.sMsgError = `Error detecting QR code: ${error.message}`;
        }
        // Continuar detectando en el siguiente cuadro de vídeo
        if (this.bEscaneando) requestAnimationFrame(onDetectar);
      };

      // Escanear continuamente cuadros de vídeo en tiempo real y detenerlo al leer el QR
      onDetectar();
    },
    // Detener el escáner y limpiar recursos
    onDetenerEscaneo() {
      this.bEscaneando = false;
      if (this.oVideoStream) {
        const tracks = this.oVideoStream.getTracks();
        tracks.forEach(track => track.stop());
      }
    },
  },
  computed: {
  },
}

var vm = null;

window.onObtenerQR = function(sCodigoQR) {
  vm.onGuardarQR(sCodigoQR);
}

window.onCancelarCamara = function() {
  vm.onCerrar();
}


</script>
<style lang="scss" scoped>
.algo{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: .5);
}
.btn-cerrar{
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
}
.btn-linterna{
  font-size: 70px !important;
}
.linterna{
  height: 30vh !important;
  position: absolute;
  z-index: 1;
}
</style>