// Objeto con los indice de perfil y las tablas a descargar 

export default {
   0 : [  // POR DEFECTO LOS OBLIGATORIOS
    { tabla: "Clientes", label: "Clientes", equiMovil: "proyecto" } ,
    { tabla: "ParametrosGenerales", label: "ParametrosGenerales", equiMovil: "parametrosgenerales" } ,
    { tabla: "Sede", label: "Sede", equiMovil: "sede" },
    { tabla: "RiesgoGrupos", label: "RiesgoGrupos", equiMovil: "TipoTipificacionGeneral" },
    { tabla: "RiesgoTipificacion", label: "RiesgoTipificacion", equiMovil: "TipoTipificacionEspecifica" } ,
    { tabla: "Departamentos", label: "Departamentos", equiMovil: "departamento" } ,
    { tabla: "Accesos", label: "Accesos", equiMovil: "accesos" } ,
    { tabla: "equivalencia", label: "Equivalencia", equiMovil: "equivalencias" } ,
    { tabla: "/puntosriesgos", label: "Puntos Riesgos", equiMovil: "punto_ronda" } ,
    { tabla: "/preguntasriesgos", label: "Preguntas riesgos", equiMovil: "preguntas" } ,
    { tabla: "CA_Actividades_Calendario", label: "Actividades Asignadas", equiMovil: "ca_actividades" } ,
   ],
   6 : [ // ESCOLTAS
    { tabla: "ESCOLTA_zona", label: "ESCOLTA zona", equiMovil: "zonas_escolta" },
    { tabla: "ESCOLTA_tipo_acompanamiento", label: "ESCOLTA tipo acompanamiento", equiMovil: "tipos_acompanamiento" },
    { tabla: "ESCOLTA_planta", label: "ESCOLTA planta", equiMovil: "plantas_escoltas" },
    { tabla: "ESCOLTA_tipo_empaque", label: "ESCOLTA tipo empaque", equiMovil: "tipos_empaque" },
   ], 
   7 : [ // PARQUEADERO
    { tabla: "PARQUEADERO_Estados", label: "PARQUEADERO Estados", equiMovil: "estado_parqueadero" },
    { tabla: "/espaciosparqueo", label: "Espacios parqueo", equiMovil: "parqueo_espacio" },
    { tabla: "PARQUEADERO_Tipos_Vehiculo", label: "PARQUEADERO Tipos Vehiculo", equiMovil: "tipo_vehiculo" },
    { tabla: "TipoPersona", label: "TipoPersona", equiMovil: "tipopersona" },
    { tabla: "PARQUEADERO_Accesos_Parqueadero", label: "PARQUEADERO Accesos Parqueadero", equiMovil: "acceso_vehiculo" },
    { tabla: "PARQUEADERO_Marcas", label: "PARQUEADERO Marcas", equiMovil: "marcas_vehiculo" },
   ], 
   8 : [ // ELEMENTOS 
    { tabla: "ELEMENTOS_Propietario", label: "ELEMENTOS Propietario", equiMovil: "propietarios" }, 
    { tabla: "ELEMENTOS_TipoElemento", label: "ELEMENTOS TipoElemento", equiMovil: "tipos_elemento" }, 
    { tabla: "TipoPersona", label: "TipoPersona", equiMovil: "tipopersona" }, 
   ],
   10 : [ // Visitantes
    { tabla: "VISITANTES_Tipos_Vehiculo", label: "VISITANTES Tipos_Vehiculo", equiMovil: "tipo_vehiculo_visitantes" },
    { tabla: "VISITANTES_Estados", label: "VISITANTES Estados", equiMovil: "estado_visitantes" },
    { tabla: "VISITANTES_TipoElemento", label: "VISITANTES TipoElemento", equiMovil: "tipo_elementos_visitantes" },
   ],
   12 : [ //CORRESCPONDENCIA
    { tabla: "CORRESPONDENCIA_Tipos", label: "CORRESPONDENCIA Tipos", equiMovil: "tipo_corres" },
   //  { tabla: "CORRESPONDENCIA_Remitentes", label: "CORRESPONDENCIA Remitentes", equiMovil: "remitentes_corres" },
   ],
   22 : [ //DINAMICOS
    { tabla: "FD_Grupos", label: "FD Grupos", equiMovil: "fd_grupo" },
    { tabla: "FD_Formulario", label: "FD Formulario", equiMovil: "fd_formulario" },
    { tabla: "formularios-dinamicos/preguntas", label: "FD preguntas", equiMovil: "fd_preguntas" },
   ]
}
