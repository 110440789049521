<template>
    <v-container fluid>
 
      <!-- Tabs de Pendientes y Finalizado -->
      <v-tabs 
        v-model="activeTab"
        rounded 
        height="40"
        class="tabs-estilo my-2"
        fixed-tabs
        :hide-slider="true">
        <v-tab 
          :class="{ 'tab-selected': activeTab === 0, 'tab-unselected': activeTab !== 0 }"
        > 
          {{ $STR.lblPendientes }}
          <v-chip class="ml-2" color="white" text-color="primary" small>{{ rondasPendientes.length }}</v-chip>
        </v-tab>
        <v-tab 
          :class="{ 'tab-selected': activeTab === 1, 'tab-unselected': activeTab !== 1 }"
        >
        {{ $STR.lblFinalizados }}
          <v-chip class="ml-2" color="white" text-color="primary" small>{{ rondasFinalizadas.length }}</v-chip>
        </v-tab>
      </v-tabs>

      <!-- Subitulos -->
      <v-row class="d-flex justify-space-between align-center my-2" background-color="cyan lighten-1">
        <v-col class="d-flex justify-start">
          Hoy
        </v-col>
      </v-row>
      <div v-if="rondasAMostrar.length > 0">
        <v-col class="pa-0 mb-2" cols="12" v-for="(ronda, index) in rondasAMostrar">
          <RondaItemCard
            :key="index"
            :oRonda="ronda"
            @onClickRonda="onClickRonda"
            @onRondaBloqueada="onRondaBloqueada"/>
        </v-col>
      </div>
      <InformacionSinActividades v-else 
        :sTitulo="$STR.msgRondasFinalizadas"
        :sDescripcion="$STR.msgSinRondasPendientes"
        :sImagen="'/img/icons/ic_sin_tareas_pendientes.svg'"
        />
      <DialogMensaje
        v-model="bDialog"
        :titulo="sTituloMensaje"
        :texto="sMensaje"
        :textoBtn="sTextoBtnAceptar"
        :bShowCancelar="bBtnCancelDialgo"
        @onAceptar="fAceptar"
        >
        <template v-slot:icono>
          <v-icon large color="red"> mdi-alert</v-icon>
        </template>
      </DialogMensaje>
    </v-container>
  </template>
  
  <script>
  import RondaItemCard from '@/components/control-actividades/rondas/RondaItemCard.vue'
  import InformacionSinActividades from '@/components/control-actividades/rondas/InformacionSinActividades.vue'
  import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
  import DialogMensaje from '@/components/DialogMensaje.vue'
  import HoraFecha from "@/utils/Hora_Fecha.js"

  export default {
    name: 'DashboardRondas',
    components:{
      RondaItemCard,
      InformacionSinActividades,
      DialogMensaje
    },
    data() {
      return {
        activeTab: 0,
        rondas: [],
        aRondas: [],
        oRondaEnProgreso: null,
        oRondaEnCompletada: null,
        oRondaCompletada: null,
        error: '',
        bDialog: false,
        bBtnCancelDialgo: false,
        sTituloMensaje: '',
        sMensaje: '',
        sTextoBtnAceptar: '',
        fAceptar:()=>{},
      };
    },
    computed: {
      rondasPendientes() {
        return this.rondas.filter(ronda => !ronda.finalizado);
      },
      rondasFinalizadas() {
        return this.rondas.filter(ronda => ronda.finalizado);
      },
      rondasAMostrar() {
        return this.activeTab === 0 ? this.rondasPendientes : this.rondasFinalizadas;
      }
    },
    mounted() {
      this.$store.dispatch('setbNavegacion', false)
      this.onConsultarRondas()
    },
    methods: {
      onConsultarRondas(){
        this.rondas = RondasRepository.onGetRondas()
        this.rondas.forEach((oRonda)=>{
          if(oRonda.estado == "En progreso"){
            this.oRondaEnProgreso = oRonda
          }else if(oRonda.finalizado){
            this.oRondaEnCompletada = oRonda
          }
        })
      },      
      onClickRonda(oRonda) {
        const sFechaActual = HoraFecha.getDia()
        
        if(this.oRondaEnProgreso !== null && this.oRondaEnProgreso.id !== oRonda.id){
          this.bDialog = true
          this.bBtnCancelDialgo = false
          this.sTituloMensaje = this.$STR.ttlRondaEnCurso
          this.sMensaje = this.$STR.msgRondaBloqueadaPorRondaEnProgreso
          this.sTextoBtnAceptar = this.$STR.btnAceptar
          this.fAceptar = () =>{}
          return 
        }

        if(this.oRondaEnCompletada !== null && this.activeTab === 0){
          const nDiferenciaMinutos = HoraFecha.onGetDiferenciaEntreFechas(this.oRondaEnCompletada?.sFechaEjecucion, HoraFecha.getNow())
          if(nDiferenciaMinutos < parseInt(this.oRondaEnCompletada?.nIntervaloTiempoEjecucion)){
            this.bDialog = true
            this.bBtnCancelDialgo = false
            this.sTituloMensaje = this.$STR.ttlRondaBloqueada
            this.sMensaje = this.$STR.msgRondaBloqueadaEnEspera
            this.sTextoBtnAceptar = this.$STR.btnAceptar
            this.fAceptar = () =>{}
            return
          }
        }

        if(oRonda.hora != 'Flexible'){
          const sFechaHoraActual = `${sFechaActual} ${HoraFecha.getHora()}`
          const sfechaHoraInicio = `${sFechaActual} ${oRonda.hora}`
          if(this.activeTab === 0 && oRonda.estado == "Sin iniciar"){
            const nDiferencia = HoraFecha.onCompararFechaHora(sfechaHoraInicio, sFechaHoraActual)
            if(nDiferencia < 0){
              this.bDialog = true
              this.bBtnCancelDialgo = false
              this.sTituloMensaje = this.$STR.ttlRondaBloqueada
              this.sMensaje = this.$STR.msgRondaBloqueadaHoraInicio
              this.sTextoBtnAceptar = this.$STR.btnAceptar
              this.fAceptar = () =>{}
              return
            }
          }
        }

        if(oRonda.estado == "Bloqueado" && this.activeTab === 0){
          // Ronda Pendiente Bloqueada
          return
        }

        this.$store.dispatch('setbNavegacion', true)
        this.$router.push(`/PuntosPorRonda/${oRonda.id}`);
      },
      onRondaBloqueada() {
        this.bDialog = true
        this.bBtnCancelDialgo = false
        this.sTituloMensaje = this.$STR.ttlRondaBloqueada
        this.sMensaje = 'El punto leido no se encuentra asignado a esta ronda.'
        this.sTextoBtnAceptar = this.$STR.btnAceptar
        this.fAceptar = () =>{}
      }
    },
  };
  </script>
  
  <style scoped>
  
  .v-list-item-content {
    font-size: 14px;
  }
  
  /* Estilo para el tab seleccionado */
  .tab-selected {
    background-color: #00A7C6 !important; /* Azul */
    color: #ffffff !important;
    border-radius: 23px !important; 
  }

  .tabs-unselected {
    background-color: #ffffff !important; /* Gris oscuro */
    color: #757575 !important;
    border-radius: 23px !important; 
  }
  
  /* Estilo para el tab no seleccionado */
  .tabs-estilo {
    background-color: #757575 !important; /* Gris oscuro */
    color: #757575 !important;
    border-radius: 23px !important; 
  }
  .tabs-estilo::v-deep .v-slide-group__prev--disabled {
    display: none  !important;
  }

  </style>
  