// Objeto con los indice de perfil y las tablas a descargar 

export default {
   0 : [  // POR DEFECTO LOS OBLIGATORIOS
    { tabla: "Clientes", label: "Clientes", equiMovil: "Clientes" } ,
    { tabla: "ParametrosGenerales", label: "ParametrosGenerales", equiMovil: "parametrosgenerales" } ,
    { tabla: "sede", label: "Sede", equiMovil: "sede" },
    { tabla: "RiesgoGrupos", label: "RiesgoGrupos", equiMovil: "TipoTipificacionGeneral" },
    { tabla: "RiesgoTipificacion", label: "RiesgoTipificacion", equiMovil: "TipoTipificacionEspecifica" } ,
    { tabla: "Departamentos", label: "Departamentos", equiMovil: "departamento" } ,
    { tabla: "Dependencias", label: "Dependencias", equiMovil: "dependencisa" } ,
    { tabla: "Ciudades", label: "Ciudades", equiMovil: "Ciudades" } ,
    { tabla: "Puestos", label: "Puestos", equiMovil: "tb_puestos" } ,
    { tabla: "equivalencia", label: "Equivalencia", equiMovil: "equivalencias" } ,
    { tabla: "/riesgos/puntos", label: "Puntos Riesgos", equiMovil: "puntos_ronda" } ,
    { tabla: "CA_Actividades_Calendario", label: "Actividades Asignadas", equiMovil: "ca_actividades" },
    //DINAMICOS Siempre por defecto en SEIF
    { tabla: "FD_Grupos", label: "FD Grupos", equiMovil: "Grupo" },
    { tabla: "FD_Formulario", label: "FD Formulario", equiMovil: "Formulario" },
    { tabla: "formularios-dinamicos/preguntas", label: "FD preguntas", equiMovil: "preguntas" },
   ],
   1 : [ //OPERACIONES
    { tabla: "COP_Tipos_Gestion", label: "Tipo Gestión", equiMovil: "tipo_gestion" },
    { tabla: "COP_Novedades_Operativas_Tipo_Novedad", label: "Tipo Novedad", equiMovil: "TipoNovedad" },
    { tabla: "COP_Verificacion_Estado", label: "Verificacion y Estado", equiMovil: "VerifciacionEstado" },
    { tabla: "COP_Tipos_Visita", label: "Tipo Visita", equiMovil: "tipo_visita_cop" },
    { tabla: "COP_Tipos_Evento", label: "Tipo Evento", equiMovil: "tipo_evento_cop" },
    { tabla: "COPSECANCOL_Preguntas", label: "Preguntas Actas", equiMovil: "calificacion_preguntas" },
    { tabla: "COPATEMPI_Preguntas", label: "Preguntas Calificación", equiMovil: "calificacion_preguntas_dina" },
    { tabla: "COP_Partes_Moto_Inspeccion", label: "Partes Vehiculo", equiMovil: "partes_vehiculo" },
    { tabla: "COP_Tipo_Vehiculo", label: "Tipo Vehiculo", equiMovil: "COP_Tipo_Vehiculo" },
    { tabla: "RiesgoGrupos", label: "Tipificación General", equiMovil: "TipoTipificacionGeneral" },
    { tabla: "RiesgoTipificacion", label: "Tipificación Específica", equiMovil: "TipoTipificacionEspecifica" },
    { tabla: "COP_Tipo_Tiempo", label: "Tiempo Actividad", equiMovil: "COP_Tipo_Tiempo" },
   ],
   2 : [ //PQRS
    { tabla: "PQR_Tipos_requerimiento", label: "Tipos Requerimiento", equiMovil: "PQR_Tipos_requerimiento" },
    { tabla: "PQR_Prioridad_requerimiento", label: "Prioridades", equiMovil: "PQR_Prioridad_requerimiento" },
    { tabla: "PQR_Tipos_Cliente", label: "Tipo Cliente Pqr", equiMovil: "TipoClientePqr" },
   ],
   3 : [ //COMERCIAL
    { tabla: "/comercial/prospectos", label: "Cliente Prospecto", equiMovil: "prospecto" },
    { tabla: "Categoria_Cliente", label: "Tipo Cliente", equiMovil: "Tipo_Cliente" },
    { tabla: "COMERCIAL_Tipos_Sector", label: "Tipo Sector", equiMovil: "tipo_sector" },
    { tabla: "COMERCIAL_Tipos_Servicio", label: "Tipo Servicio", equiMovil: "tipo_Servicio" },
    { tabla: "COMERCIAL_Servicios", label: "Servicios", equiMovil: "servicio" },
    { tabla: "comercial/agenda", label: "Calendario", equiMovil: "cqalendario" },
   ],
}
