<template>
  <!-- Componente tipo modal para ampliar fotos y texto 
  Tiene dos props para cargar los textos e imagen
  Se debe declarar para su uso el con model el v-model
  -->
  <v-dialog v-model="modelShow" persistent :overlay="false" max-width="500px"
  transition="dialog-bottom-transition" >
    <v-card class="pa-3 justify-center align-center text-center">
      <v-col no-gutters class="pa-0 d-flex justify-end" v-if="!bOcultarCancelar">
        <v-btn
          icon
          x-small
          @click="onCancelar"
          >
          <v-icon large> mdi-close </v-icon>
        </v-btn>
      </v-col>
      <slot name="icono"></slot>
      <v-card-title 
        color="primary"
        class="text-medium pt-1 justify-center text-size-2"
        >{{ titulo }}</v-card-title>
      <div class="py-3 px-3" v-if="imagen != ''">
          <img :src="imagen" width="150px">
      </div>
      <v-card-text
        class="text-medium justify-center text-size-1"
        >{{ texto }}
      </v-card-text>
      <v-col>
        <v-btn v-if="bShowCancelar"
          outlined
          class="btn-primary mt-1 mr-2 text-transform-initial"
          color="primary"
          dark
          @click="onCancelar"
          >
          Cancelar
        </v-btn>
        <v-btn
          class="btn-primary mt-1 margin-auto text-transform-initial"
          color="primary"
          dark
          @click="onAceptar"
          >
          {{ textoBtn }}
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name:'DialogMensaje',
    model:{
      prop: 'modelShow',
      event: 'click'
    },
    props: {
      modelShow: Boolean,
      titulo:{
        default:''
      },
      imagen:{
        default:''
      },
      texto:{
        default:''
      },
      bShowCancelar:{
        default: false
      },
      textoBtn:{
        default:'Aceptar'
      },
      autoClose: {
        type: Number,
        default: 0, // tiempo en segundos
      },
      bOcultarCancelar:{
        default: false
      }
    },
    
    data() {
      return {
        timeoutId: null
      }
    },
    watch: {
      modelShow(value) {
        if (value) {
          this.onIniciarCloseTime();
        } else {
          this.onLimpiarCloseTime();
        }
      }
    },
    methods: {
      onCancelar(){
        this.$emit('click', false)
        this.$emit('onCancelar', true)
      },
      onAceptar(){
        this.$emit('click', false)
        this.$emit('onAceptar', true)
      },
      onIniciarCloseTime() {
        this.onLimpiarCloseTime();
        if (this.autoClose > 0) {
          this.timeoutId = setTimeout(() => {
            this.$emit('click', false)
          }, this.autoClose * 1000);
        }
      },
      onLimpiarCloseTime() {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
      },
    },
    
  }
</script>

<style lang="scss" scoped>
// corrige un margin negativo que está declarado en algún lugar del universo de vuetify 
.row {
  margin: 0!important;
}
.cont-resp-texto{
  font-size: 0.8em;
}
.under-sec{
  padding: 2px!important;
}
.btn-img{
  border-radius: 10px;
  img{
    width: 100%;
  }
}

.margin-auto{
  margin: 0 auto;
}
</style>