import ControlActividadesUtils from '@/utils/ControlActividadesUtils.js'
import axiosServices from '@/services/axiosServices.js'
import store from '@/store/index.js'
import ValidarBD from "@/utils/ValidarBD.js"
import Hora_Fecha from '@/utils/Hora_Fecha'

export default {
  onFormatearFecha(fecha) {
    let formattedDate = "";
    // Verifica si la fecha es un string y de ser asi devolvemos directamente
    if (typeof fecha === 'string') {
      formattedDate = fecha
    } 
    // Verifica si la fecha es un objeto MongoDB (UTCDateTime)
    else if (typeof fecha === 'object' && fecha !== null) {
      if (fecha.$date && fecha.$date.$numberLong) {
        formattedDate = Hora_Fecha.getDateTimeBy(Number(fecha.$date.$numberLong));
      } 
    } 
    return formattedDate;
  },

  onBuildRonda(oRonda){

    if (oRonda == undefined || oRonda==null) return oRonda

    const bodyParsed = oRonda.detalle;
    let sLabelPuntos = ''
    let nPuntosMarcados = 0
   
    let sLabelCantidadRondas = ''
    let oRespuesta = {}
    if(Object.prototype.hasOwnProperty.call(bodyParsed, 'oRespuesta')) {
      oRespuesta = bodyParsed.oRespuesta
    }else{
      oRespuesta.sFechaEjecucion = ''
      oRespuesta.sEstado = 'Sin iniciar'
      oRespuesta.bFinalizada = false
    }
    const aPuntosMarcados = bodyParsed.oDetalle.aPuntos.filter(oPunto => oPunto?.sEstadoMarcacion === "Marcado")
    let sFechaEjecucion = this.onFormatearFecha(oRespuesta.sFechaEjecucion)

    nPuntosMarcados = aPuntosMarcados.length
    sLabelPuntos = `(${nPuntosMarcados} de ${bodyParsed.oDetalle.aPuntos?.length})`

    return {
        id:oRonda.idActividadCalendario,
        sIdActividad: bodyParsed.sIdActividad,
        nombre: `${bodyParsed.sNombre} ${sLabelCantidadRondas}`,
        puntos: sLabelPuntos, 
        hora: bodyParsed.sHoraInicio || 'Flexible',
        estado: oRespuesta.sEstado,
        nPuntosMarcados: nPuntosMarcados,
        nPuntosTotales: bodyParsed.oDetalle.aPuntos?.length,
        finalizado: oRespuesta.bFinalizada,
        sFechaEjecucion: sFechaEjecucion,
        nIntervaloTiempoEjecucion: bodyParsed.nIntervaloTiempoEjecucion,
        bActivo: (bodyParsed.sEstado !== 'Bloqueado')
    };
  },

  onGetRondas(){
    let aRondas = [];
    const result = ControlActividadesUtils.onGetActividadesByTipoConFecha(
      ControlActividadesUtils.TipoActividades.Rondas, Hora_Fecha.getDia()
    )
    let bRondaEnProgreso= false
    let idActividadEnProgreso=""
    result.forEach((rawRonda, index) => {
      const oRonda = this.onBuildRonda(rawRonda)
      if(oRonda.estado == "En progreso"){
        bRondaEnProgreso = true
        idActividadEnProgreso = oRonda.id
      }
      aRondas.push(oRonda)
    })

    if(bRondaEnProgreso){
      aRondas.forEach((ronda) => {
        if(ronda.id != idActividadEnProgreso){
          ronda.bActivo = false
          ronda.estado = "Bloqueado"
        }
      })
    }
    return aRondas
    
  },

  onGetEstadoMarcacionPunto(aRonda, nIdPunto){
    let sEstadoMarcacion = "Sin marcar"
    const oPunto = aRonda.oDetalle.aPuntos.find(punto => punto.idPunto === nIdPunto)
    if(oPunto){
      if(Object.prototype.hasOwnProperty.call(oPunto, 'sEstadoMarcacion')) {
        sEstadoMarcacion = oPunto.sEstadoMarcacion
      }
    }
    return sEstadoMarcacion
  },

  onGetCantidadPuntosMarcados(aRondaDetalle){
    const aPuntosMarcados = aRondaDetalle.oDetalle.aPuntos.filter(oPunto => oPunto.sEstadoMarcacion === "Marcado")
    return aPuntosMarcados.length 
  },

  onGetPuntosByRonda(sIdActividadCalendario){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if(oRonda == undefined){
      return []
    }
    const aParametros = ValidarBD.getInfoTabla('ParametrosGenerales')
    const urlBaseImagen = aParametros.find(item => item.Parametro == 'Path_imagenes')
    return oRonda.detalle.oDetalle.aPuntos.map((punto) => {
      return {
        sIdPunto: punto.idPunto,
        sNombrePunto: punto.NombrePunto,
        sNombreRonda: oRonda.detalle.sNombre,
        sEstado: this.onGetEstadoMarcacionPunto(oRonda.detalle, punto.idPunto),
        sTipoMarcacion: (store.state.bMarcacionQr) ? "QR" : "NFC",
        sDescripcion: (punto.Observaciones)? punto.Observaciones : '',
        sFoto: (punto.Foto_punto)? urlBaseImagen.Observacion+punto.Foto_punto : '',
      }
    })
  },

  onGetRondaById(sIdActividadCalendario){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    return this.onBuildRonda( actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario) )
  },

  onExistenRondas(){
    const result = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    return result.length > 0
  },

  onGetPreguntasPuntoRonda(sIdActividadCalendario, nIdPunto){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if(oRonda == undefined){
      return []
    }

    const oPunto = oRonda.detalle.oDetalle.aPuntos.find(punto => punto.idPunto == nIdPunto)
    if(oPunto == undefined){
      return []
    }
   
    return oPunto.PreguntasPunto.map(function (pregunta, index) {
      return {
        nIndex:index,
        idPregunta: pregunta.idCA_Preguntas,
        sPregunta: pregunta.Pregunta,
        nObligatorio: pregunta.Obligatorio
      }
    })
  },

  onGetPuntoByCodigo(sIdActividadCalendario, sCodigo){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if(oRonda == undefined){
      return undefined
    }
    const sTipoCodigo = (store.state.bMarcacionQr) ? "QR" : "NFC"
    const punto = oRonda.detalle.oDetalle.aPuntos.find(punto => punto[sTipoCodigo] == sCodigo)
    if(punto == undefined){
      return undefined
    }
    const oPunto = {
      sIdPunto: punto.idPunto,
      sNombrePunto: punto.NombrePunto,
      sNombreRonda: oRonda.detalle.sNombre,
      sEstado: punto.sEstadoMarcacion,
      sTipoMarcacion: punto.sTipoMarcaion,
      sDescripcion: punto.Observaciones,
      sFoto: punto.sFoto,
      aPreguntas: punto.PreguntasPunto.map(function (pregunta, index) {
        return {
          nIndex:index,
          idPregunta: pregunta.idBancoPreguntas,
          sPregunta: pregunta.Pregunta,
          nObligatorio: pregunta.Obligatorio
        }
      })
    }
    return oPunto
  },

  async onReporteMarcacion(sParams, oBody, aFotos=[], bIsApp=false){
    const oRSP = await axiosServices.postConfiguration('/rondas/marcacion', oBody, true, sParams )
    // envio las fotos que tego guardadas en aFotos si no estoy con APP
    if(!bIsApp){
      aFotos.forEach( async foto => {
        // se recorren objetos todos tienenl a foto en base64, pero si es firma tienen el nombre en Firma y si es foto el nombre esta en NombreFoto
        if(('NombreFoto' in foto)){
          axiosServices.postFoto(`${foto.NombreFoto}`, foto.Base64)
        }
        if(('Firma' in foto)){
          axiosServices.postFoto(`${foto.Firma}`, foto.Base64)
        }
      })
    }
    return (oRSP) ? oRSP : [];
  },

  async onReporteFinalizarRonda(sParams, oBody, aFotos=[], bIsApp=false){
    const oRSP = await axiosServices.postConfiguration('/rondas/finalizar', oBody, true, sParams )
    // envio las fotos que tego guardadas en aFotos si no estoy con APP
    if(!bIsApp){
      aFotos.forEach( async foto => {
        // se recorren objetos todos tienenl a foto en base64, pero si es firma tienen el nombre en Firma y si es foto el nombre esta en NombreFoto
        if(('NombreFoto' in foto)){
          axiosServices.postFoto(`${foto.NombreFoto}`, foto.Base64)
        }
        if(('Firma' in foto)){
          axiosServices.postFoto(`${foto.Firma}`, foto.Base64)
        }
      })
    }
    return (oRSP) ? oRSP : [];
  },

  onGetAllPuntos(sTablaPuntos){
    let tabla = store.state.dataBase.dataBase.find( table => table.tablaName == sTablaPuntos)
    const aPuntos = tabla.info
    return aPuntos.map((punto) => {
      return {
        sIdPunto: punto.ID_Puntos,
        sNombrePunto: punto.nombre_punto,
        Riesgo: punto.Riesgo
      }
    })
  },

  onValidarRegistrarRespuestasPreguntas(aBody, oPunto) {
    // Verificar si existe 'aRespuestasPregunta' en aBody y que no esté vacío
    let bEsConPreguntas = 'aRespuestasPregunta' in aBody && aBody.aRespuestasPregunta.length > 0
    oPunto.sTipoVerificacion = (bEsConPreguntas) ? 'Con preguntas' : 'Básico';
    oPunto.nNovedadesPunto = 0;
    if (bEsConPreguntas) {
      // Iterar sobre las preguntas de oPunto
      oPunto.PreguntasPunto.forEach(pregunta => {
        // Iterar sobre las respuestas de aBody
        aBody.aRespuestasPregunta.forEach(preguntaRespuesta => {
          // Verificar si la pregunta actual coincide con la pregunta respondida
          if (pregunta.idBancoPreguntas === preguntaRespuesta.idPregunta) {
            // Actualizar las respuestas
            pregunta.sRespuesta = preguntaRespuesta.textoRespuesta;
            pregunta.sObservaciones = preguntaRespuesta.sObservacion;
            pregunta.fotoAnexo = preguntaRespuesta.fotoAnexo;
            if(preguntaRespuesta.textoRespuesta == "Con novedad"){
              oPunto.nNovedadesPunto+=1;
            }
          }
          });
      });

    }
    return oPunto; // Retornar el objeto oPunto modificado
  },

  onActualizarProgresoRonda(sIdActividadCalendario, idPunto, aBody, bIsApp=false){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if (oRonda == undefined || oRonda == null) {
      console.log("No existe ronda",sIdActividadCalendario);
      return
    }

    let indexPuntoMarcado = oRonda.detalle.oDetalle.aPuntos.findIndex(punto => punto.idPunto == idPunto)
    if(indexPuntoMarcado < 0){
      console.log("No existe punto marcado");
      return undefined
    }
    // Actualizamos punto marcado
    let oPuntoMarcado = oRonda.detalle.oDetalle.aPuntos[indexPuntoMarcado]
    oPuntoMarcado.sEstadoMarcacion = "Marcado";
    oPuntoMarcado.sFechaRegistro = aBody.fecha;
    oPuntoMarcado.sLatitud = aBody.Latitud;
    oPuntoMarcado.sLongitud = aBody.Longitud;
    oPuntoMarcado.aAnexos = aBody.galeria;
    oPuntoMarcado = this.onValidarRegistrarRespuestasPreguntas(aBody, oPuntoMarcado);
    oRonda.detalle.oDetalle.aPuntos[indexPuntoMarcado] = oPuntoMarcado

    // Actualizamos respuesta de la ronda y validamos si se completo la ronda
    oRonda.detalle.oRespuesta.sEstado = "En progreso"
    oRonda.detalle.oRespuesta.nTotalNovedades = Number(oRonda.detalle.oRespuesta.nTotalNovedades) + Number(oPuntoMarcado.nNovedadesPunto)
    let bMarcacionesCompletadas = true
    oRonda.detalle.oDetalle.aPuntos.forEach((punto) => {
      bMarcacionesCompletadas = bMarcacionesCompletadas && (punto.sEstadoMarcacion == "Marcado")
    })
    if(bMarcacionesCompletadas){
      oRonda.detalle.oRespuesta.sEstado = "Completada";
      oRonda.detalle.oRespuesta.sFechaEjecucion = aBody.fecha
      oRonda.detalle.oRespuesta.bFinalizada = true;
    }

    ControlActividadesUtils.onRegistrarRespuestaActivdad(sIdActividadCalendario, oRonda.detalle, bIsApp)

    return bMarcacionesCompletadas
  },

  onActualizarFinalizarRonda(sIdActividadCalendario, aBody, bIsApp=false){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if (oRonda == undefined || oRonda == null) {
      console.log("No existe ronda",sIdActividadCalendario);
      return
    }
    // Actualizamos respuesta de la ronda y validamos si se completo la ronda
    oRonda.detalle.oRespuesta.nTotalNovedades = Number(oRonda.detalle.oRespuesta.nTotalNovedades)
    let bMarcacionesCompletadas = true
    oRonda.detalle.oDetalle.aPuntos.forEach((punto) => {
      bMarcacionesCompletadas = bMarcacionesCompletadas && (punto.sEstadoMarcacion == "Marcado")
    })
    oRonda.detalle.oRespuesta.sEstado = (bMarcacionesCompletadas) ? "Completada" : "Incompleta";
    oRonda.detalle.oRespuesta.sFechaEjecucion = aBody.fecha
    oRonda.detalle.oRespuesta.bFinalizada = true;
    
    ControlActividadesUtils.onRegistrarRespuestaActivdad(sIdActividadCalendario, oRonda.detalle, bIsApp)
  },

  onActualizarEstadoRonda(sIdActividadCalendario, sEstado, bIsApp=false){
    const actividadesRondas = ControlActividadesUtils.onGetActividadesByTipo(ControlActividadesUtils.TipoActividades.Rondas)
    const oRonda = actividadesRondas.find(tabla => tabla.idActividadCalendario == sIdActividadCalendario)
    if (oRonda == undefined || oRonda == null) {
      console.log("No existe ronda",sIdActividadCalendario);
      return
    }
    // Actualizamos respuesta de la ronda y validamos si se completo la ronda
    oRonda.detalle.oRespuesta.sEstado = sEstado;
    oRonda.detalle.oRespuesta.bFinalizada =  (sEstado == "Completada" || sEstado == "Incompleta");
  
    ControlActividadesUtils.onRegistrarRespuestaActivdad(sIdActividadCalendario, oRonda.detalle, bIsApp)
  },


}