<template>
  <v-bottom-navigation 
  v-model="value"
  :key="nKeyContenedor" 
  max-width="100vw"
  class="navigator rounded-t-lg overflow-hidden mx-auto" 
  height="50px" 
  light fixed 
  grow>


    <v-btn v-for="(itembtn,index) in databtn" 
    :key="'option'+index" 
    :value="itembtn.valuename" 
    x-small min-width="50"
    :to="itembtn.pathto"
    @click="goTo(itembtn.link)">
      <span>{{itembtn.spanname}}</span>

      <v-badge
        :content="itembtn.badgeValue"
        :value="itembtn.badgeValue"
        color="red"
        overlap >
        <v-icon >{{itembtn.iconname}}</v-icon>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name:"NavBarFooter",
  data() {
    return {
      value:1,
      nKeyContenedor:1,
      databtn: [
          {valuename:'home',spanname:'Inicio',iconname:'mdi-home',link:"Home", badgeValue : 0},
          {valuename:'pendientes',spanname:'Pendientes',iconname:'mdi-clock-time-eight-outline',link:"Pendientes", badgeValue : 0},
          {valuename:'database',spanname:'Base de datos',iconname:'mdi-database',link:"DescargaDB", badgeValue : 0},
          {valuename:'ayuda',spanname:'Ayuda',iconname:'mdi-comment-question-outline',link:"", badgeValue : 0},
          {valuename:'profile',spanname:'Perfil',iconname:'mdi-account',link:"", badgeValue : 0},
      ],
    }
  },
  mounted () {
    this.onValidarPermisoRondas()
  },
  methods: {
    goTo(routName) {
      this.$router.replace({name:routName})
    },
    NoPendientes(){
      const index = this.databtn.findIndex(e => e.valuename == "pendientes")
      if (index > -1) {
        this.databtn[index].badgeValue = this.getNumeroOffline
      }
    },
    onValidarPermisoRondas(){
      let oActivityPermiso = this.getaPermisos.find( item => item.Nombre_app == "ActivityActividadesRondas")
      if(oActivityPermiso){ 
        if(oActivityPermiso.permiso == "1"){
          this.databtn.splice(3, 1)
          this.databtn.splice(1, 0, {valuename:'rondas',spanname:this.$STR.ttlRondasAsignadas,iconname:'mdi-clipboard-text-outline',link:"DashboardRondas", badgeValue : 0})
        }
      }
      this.NoPendientes()
    }
  },
  computed: {
    ...mapGetters(['getaPermisos']),
    ...mapGetters('axiosOffline', ['getNumeroOffline'])
  },
  watch: {
    getNumeroOffline() {
      this.NoPendientes()
    },
    getaPermisos(){
      this.onValidarPermisoRondas()
    }
  },
}
</script>
<style lang="scss" scoped>
span{
    font-size: 0.6em;
}
.navigator{
    background: var(--v-backwhite-base)!important;
    color: var(--v-primaryb-base)!important;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.4)!important;
}

</style>