<template>
<v-flex xs12 sm5 md3 my-1 mx-1>
  <v-card
    elevation="2"
    outlined>
    <v-list-item  class="item-container" :class="getColor">
      <v-list-item-content>
        <v-list-item-title class="text-item">
          {{oTabla.label}}
        </v-list-item-title>
        <v-col class="text-data" cols="2" :key="nKeyActualizar">
          {{ cmp_nRegistroloca }}
        </v-col>
      </v-list-item-content>
      <v-list-item-avatar
        tile
        size="50"
        class="avatar">
     <Icono 
        :isActivo="isDescargando"
        :estado="cmp_icono"
        @descargar="descargar">
      </Icono>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</v-flex>
</template>

<script>
  import ValidarBD from "@/utils/ValidarBD.js"
  import Icono from "@/components/descargadb/IconEstado.vue"
  import DinamicosUtils  from "@/utils/DinamicosUtils.js"

  export default {
    name: "DescargaTB",
    props: {
      oTabla: {
        type: Object,
        default: () => {}
      },
      index: {
        type: Number,
      },
    },
    components: {
      Icono,
    },
    data() {
      return {
        isDescargando : false,
        nKeyActualizar : 0
      }
    },
    methods: {
      async descargar() {

        this.$emit('descargar', this.oTabla, this.index)  // Descargando..
        /*
        let idIcono
        this.isDescargando = true
        
        this.$emit('descargado', {index : this.index, icono : 1 })  // Descargando..
        const oRespuesta = await ValidarBD.descargarTB(this.oTabla.tabla)

        // Si descargue Form_Dinamicos_reguntas  toca descargar los valores adicionales.
        if( this.oTabla.tabla == 'formularios-dinamicos/preguntas') {
         await DinamicosUtils.getPreguntasValores()
        }

        if(oRespuesta.sStatus == 'success'){
          // todo salio bien
          idIcono = 2   // Descargado
          this.$store.dispatch('dataBase/salvarTabla', {tablaName : this.oTabla.tabla, info: oRespuesta.aData})
          ValidarBD.TB_actualizarFechadescarga(this.oTabla.equiMovil, oRespuesta.aData.length)
          this.oTabla.nRegistros = oRespuesta.aData.length
        }else{
          // fallo
          idIcono = 3   // Errror
        }

        this.$emit('descargado', {index : this.index, icono : idIcono })
        this.isDescargando = false*/


      }
    },
    computed: {
      cmp_nRegistroloca() {
        this.nKeyActualizar++  // eslint-disable-line
        return this.oTabla.nRegistros
      },
      cmp_icono() {
        this.isDescargando = this.oTabla.icono === 1 ?  true : false  // eslint-disable-line
        return this.oTabla.icono
      },
      getColor() {
        let color = ''
        switch (this.cmp_icono) {
          case 0:
           color =  'amber lighten-3'
          break;
          case 3:
           color =  'red lighten-3'
          break;
        
        }
        return color
      }

    },
  }
</script>

<style lang="scss" scoped>
.avatar{
  margin: left auto;
}
.text-item{
    font-size: 1em;
    color: #0E3143;
    font-weight: 500;
}
.item-container{
  padding: 2px 0px 2px 10px;
  text-align: left;
  
}
.text-data{
  padding: 5px 3px;
  background: #ECEFF0;
  color: #0E3143;
  text-align: center;
  border-radius: 5px;
  font-size: 1em;
  font-weight:400;
}
</style>