<template>
  <div class="margen-form">
    <v-container>

      <!--Seccion titulo-->
      <v-col class="pa-0 mb-2" cols="12">    
        <HeaderVista :sTitulo="sTitulo" sColor="black--text">
          <template v-slot:icono>
            <v-icon large color="red"> mdi-map-marker </v-icon>
          </template>
        </HeaderVista>
      </v-col>


      <v-card
        width="100%"
        class="pa-5 mt-5 card-rounded over-hidden"
      >
        <ContenedorCampos :titulo="$STR.cmpObs" :isObligatorio="'1'" />
        <v-textarea
          v-model="sObservacion"
          :placeholder="$STR.msgRazonesFinalizarRondaIncompleta"
          rows="3"
          row-height="10"
          outlined
          required
        />

        <v-col no-gutters class="pa-0 mt-3"> 
          <Fotos
            :key="oKeyFoto"
            v-model="aFotos"
            :sTitulo='$STR.lblAnexos'
          />
        </v-col>

      </v-card>
      <v-sheet
        class="text-center contenedor-bottom mt-5"
        >
          <v-col class="pa-0">
            <v-row no-gutters justify="center" align="center">
              <v-btn block @click="onEnviar" large dark color="primary" class="btn-primary btn-rounded">
               {{ $STR.btnFinalizarRonda }}
              </v-btn>
            </v-row> 
            <v-row no-gutters  justify="center" align="center" class="mt-2">
              <v-btn block @click="onCancelar" large dark color="error" outlined class="btn-primary btn-rounded">
                {{ $STR.btnCancelar }}
              </v-btn>
            </v-row>  
          </v-col>
      </v-sheet>
    </v-container>
    <DialogMensaje
      v-model="bDialogCamposObligatorios"
      :titulo="sTituloMensaje"
      :texto="sMensajeError"
    >
      <template v-slot:icono>
        <v-icon large color="red"> mdi-alert</v-icon>
      </template>
    </DialogMensaje>
  </div>
  
</template>
<script>
import RondasRepository  from '@/views/control-actividades/rondas/RondasRepository.js'
import HeaderVista from '@/components/HeaderVista.vue';

import { mapGetters } from 'vuex'
import EditTextAlfanumerico from '@/components/dinamicos/campos/EditTextAlfanumerico.vue';
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue';
import Fotos from '@/components/Fotos.vue'
import DialogMensaje from '@/components/DialogMensaje.vue'
import APPutils from '@/utils/APPutils.js'
import HoraFecha from "@/utils/Hora_Fecha.js"
import GPSLocation from '@/utils/GPSlocation.js'

export default {
  name: 'FinalizarRondaIncompleta',
  components: {
    HeaderVista,
    EditTextAlfanumerico,
    ContenedorCampos,
    Fotos,
    DialogMensaje
  },
  data() {
    return {
      sIdRondaCalendario : '0',
      sIdActividad : '0',
      sTitulo: '',
      sObservacion: '',
      oKeyFoto: 1,
      oRonda:null,
      aFotos:[],
      oLocation : {},
      disabledGuardar : false,
      bErrorPermisos : false ,
      bDialogCamposObligatorios:false,
      sTituloMensaje:"",
      sMensajeError:"",
      sFormulario: "Ronda Incompleta"
      
    };
  },
  async mounted() {
    this.sIdRondaCalendario = this.$route.params.idRonda;
    this.oRonda = RondasRepository.onGetRondaById(this.sIdRondaCalendario)
    
    if(this.oRonda != undefined){
      this.sTitulo = this.oRonda.nombre
      this.sIdActividad = this.oRonda.sIdActividad
    }
    try{
      this.oLocation = await GPSLocation.getCurrentPosition()
      this.bErrorPermisos = false
    }catch(e){
      if(e.message=='User denied Geolocation'){
        this.bErrorPermisos = true
      }
      // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
      APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
    } 
  },
  methods: {
    onCancelar(){
      this.$router.back()
    },
    async onEnviar(){
      if(!this.isFormularioValido()){
        const sParams = `&app=PWA`
        const aNombreFotos = [];
        this.aFotos.forEach( async foto => {
          aNombreFotos.push({ fotoNombre : foto.NombreFoto})
        })
        const oBody = {
          imei : this.getimei,
          app : "ActivityRondaIncompleta",
          tipoRespuesta: "finalizar",
          sIdActividadCalendario: this.sIdRondaCalendario,
          sIdActividad : this.sIdActividad,
          sObservacion : this.sObservacion,
          aFotos : aNombreFotos,
          Latitud : this.oLocation.coords.latitude,
          Longitud : this.oLocation.coords.longitude,
          fecha : HoraFecha.getNow(),
        }
        const oResponse = await RondasRepository.onReporteFinalizarRonda(sParams, oBody, this.aFotos, this.getisAPP)
        if(!Object.prototype.hasOwnProperty.call(oResponse, 'nStatusCode')) {
          if(this.getisAPP) {
            APPutils.enviarDataOffline(oBody, this.sFormulario)
            APPutils.onSubirFotosApp(this.sFormulario)
          }
        }else {
          if ((oResponse.nStatusCode == 200) || (oResponse.nStatusCode == 220)  ){
            if(this.getisAPP) {
              APPutils.onSubirFotosApp(this.sFormulario)
            }
            RondasRepository.onActualizarFinalizarRonda(this.sIdRondaCalendario, oBody, this.getisAPP)
            this.$router.replace(`/DashboardRondas`);
            switch (oResponse.nStatusCode) {
              case 200: {
                this.$swal({
                  timer: 1500,
                  icon: `${oResponse.sStatus}`,
                  title: `${oResponse.sMessage}`,
                  showConfirmButton: false
                })
                break
              }
              case 220: {
                  this.$swal({
                  timer: 1500,
                  icon: 'info',
                  title: `${oResponse.sMessage} # ${oResponse.aData}`,
                  showConfirmButton: false
                })
                break
              }
            }
            //APPutils.registro_OK()
          } else {
            if(this.getisAPP) {
              APPutils.enviarDataOffline(oBody, this.sFormulario)
              APPutils.onSubirFotosApp(this.sFormulario)
            }
          }
        }
      }else{
        this.sTituloMensaje="Campos requeridos"
        this.bDialogCamposObligatorios = true
      }
    },
    isFormularioValido(){
      this.sMensaje = ''
      let error = false

      if(this.sObservacion==''){
        this.sMensajeError += '- Detalle no diligenciado.'
        error = true
      }

      return error
    }, 
  },
  computed: {
    ...mapGetters(['getbMenuBlock','getimei', 'getisAPP']),
  }
}
</script>
<style lang="scss" scoped>
.contenedor-bottom {
  background-color: unset;
  bottom: 0;
  width: 100%;
  margin-bottom: 50px;
}

</style>  