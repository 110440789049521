<template>
  <v-col justify="center" align="center" >
    <v-row justify="center" align="center"  class="mt-3">
      <img :src="sImagen" width="250px">
    </v-row>
    <v-row justify="center" align="center"  class="mt-3 mb-3">
        {{ sTitulo }}
    </v-row>
    <v-row justify="center" align="center"   class="mt-3">
        {{ sDescripcion }}
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: 'InformacionPunto',
  props: {
    sTitulo: String,
    sDescripcion: String,
    sImagen: String,
  },
  data() {
    return {
    };
  },
}
</script>
<style lang="scss" scoped>

</style>  